import React, { Component } from 'react';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCardFooter,
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CFormGroup,
    CFormText,
    CInput,
    CInvalidFeedback,
    CInputRadio,
    CInputCheckbox,
    CSelect,
    CInputGroup,
    CInputGroupAppend,
    CInputGroupText,
    CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import configs from 'src/appConfigs';
import { helps } from 'src/_helpers';
import Select from 'react-select';
import Hint from 'src/_components/Hint';

class EmployeeAddForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            errors: {},
            id: '',
            fullname: '',
            employee_id: '',
            joint_date: new Date(),
            phone: '',
            height: '',
            weight: '',
            id_type: 'CCCD',
            cccd: false,
            id_number: '',
            id_issuance_date: new Date(),
            gen: 'NAM',
            branch: 'HO',
            group: 'MT',
            department: 'TMT',
            position: 'NV',
            job_type: 'NVCT',
            birthdate: new Date(),
            basic_salary: '',
            bank_account_number: '',
            bank_name: '',
            bank_note: '',
            permanent_address: '',
            home_town: '',
            reference_name: '',
            reference_phone: '',
            reference_relationship: '',
            added_by: this.props.user.id,
            added_date: new Date(),
            recruited_by: '',
            exit_status: 0,

            recruited_by_options: [],
            default_recruited_by_option: {},
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChangeCCCD = this.handleChangeCCCD.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
    }

    componentDidMount() {
        const { employee, employees } = this.props;
        var recruited_by_options = [];
        if (!helps.isEmpty(employees)) {
            employees.map((employee) => {
                let option = {
                    value: employee.employee_id,
                    label: employee.fullname + ' (' + employee.employee_id + ')'
                }
                recruited_by_options.push(option);
            });
        }
        this.setState({ recruited_by_options: recruited_by_options });

        if (employee) {
            var default_recruited_by_option = recruited_by_options.find((option) => option.value === employee.recruited_by);
            this.setState({
                id: employee.employee_id,
                fullname: employee.fullname ? employee.fullname : this.state.fullname,
                gen: employee.gen ? employee.gen : this.state.gen,
                joint_date: employee.joint_date ? employee.joint_date : new Date(),
                employee_id: employee.employee_id ? employee.employee_id : this.state.employee_id,
                phone: employee.phone ? employee.phone : this.state.phone,
                height: employee.height ? employee.height : this.state.height,
                weight: employee.weight ? employee.weight : this.state.weight,
                id_number: employee.id_number ? employee.id_number : this.state.id_number,
                cccd: employee.cccd ? employee.cccd : this.state.cccd,
                id_issuance_date: employee.employee_id_issuance_date ? employee.employee_id_issuance_date : this.state.id_issuance_date,
                id_type: employee.employee_id_type ? employee.employee_id_type : this.state.id_type,
                branch: employee.branch ? employee.branch : this.state.branch,
                group: employee.group ? employee.group : this.state.group,
                department: employee.department ? employee.department : this.state.department,
                position: employee.position ? employee.position : this.state.position,
                bank_account_number: employee.bank_account_number ? employee.bank_account_number : this.state.bank_account_number,
                bank_name: employee.bank_name ? employee.bank_name : this.state.bank_name,
                bank_note: employee.bank_note ? employee.bank_note : this.state.bank_note,
                permanent_address: employee.permanent_address ? employee.permanent_address : this.state.permanent_address,
                home_town: employee.home_town ? employee.home_town : this.state.home_town,
                reference_name: employee.reference_name ? employee.reference_name : this.state.reference_name,
                reference_phone: employee.reference_phone ? employee.reference_phone : this.state.reference_phone,
                reference_relationship: employee.reference_relationship ? employee.reference_relationship : this.state.reference_relationship,
                birthdate: employee.birthdate ? employee.birthdate : new Date(),
                basic_salary: employee.basic_salary ? employee.basic_salary : this.state.basic_salary,
                job_type: employee.job_type ? employee.job_type : this.state.job_type,
                added_by: employee.added_by ? employee.added_by : this.state.added_by,
                added_date: employee.added_date ? employee.added_date : this.state.added_date,
                recruited_by: employee.recruited_by,
                exit_status: employee.exit_status ? employee.exit_status : 0,

                default_recruited_by_option: default_recruited_by_option,
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            let employee = {
                id: this.state.id,
                fullname: this.state.fullname,
                gen: this.state.gen,
                joint_date: new Date(this.state.joint_date),
                phone: this.state.phone,
                height: this.state.height,
                weight: this.state.weight,
                cccd: this.state.cccd,
                id_number: this.state.id_number,
                id_type: this.state.id_type,
                id_issuance_date: new Date(this.state.id_issuance_date),
                branch: this.state.branch,
                group: this.state.group,
                department: this.state.department,
                position: this.state.position,
                bank_account_number: this.state.bank_account_number,
                bank_name: this.state.bank_name,
                bank_note: this.state.bank_note,
                permanent_address: this.state.permanent_address,
                home_town: this.state.home_town,
                reference_name: this.state.reference_name,
                reference_phone: this.state.reference_phone,
                reference_relationship: this.state.reference_relationship,
                birthdate: new Date(this.state.birthdate),
                basic_salary: this.state.basic_salary,
                job_type: this.state.job_type,
                added_by: this.state.added_by,
                added_date: this.state.added_date,
                recruited_by: this.state.recruited_by,
                exit_status: this.state.exit_status,
            }
            if (helps.isEmpty(employee.id)) {
                employee['employee_id'] = this.state.employee_id;
            } else {
                employee['employee_id'] = this.props.employee.employee_id;
            }
            //console.log(employee);
            this.props.handleSubmit(employee);
        } else {
            return false;
        }
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
    }

    handleChange_Get_ID(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
        let _ID = helps.get_Employee_ID(this.state.fullname, this.state.id_number);
        this.setState({ employee_id: _ID });
        //console.log(_ID);
    }

    handleChangeSelect(selectedOptions, field) {
        console.log(selectedOptions)
        if (selectedOptions) {
            this.setState({ [field]: selectedOptions.value, default_recruited_by_option: selectedOptions });
        } else {
            this.setState({ [field]: '', default_recruited_by_option: {} });
        }

    }

    handleChangeFilterDate(_date) {
        let fields = this.state.fields;
        fields['joint_date'] = _date;
        this.setState({ joint_date: _date });
        this.setState({ fields });
    }

    handleChangeDate(_date, field) {
        this.setState({ [field]: _date });
    }


    handleChangeCCCD(e) {
        this.setState({ cccd: e.target.checked });
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(this.state.fullname)) {
            formIsValid = false;
            errors["fullname"] = "Vui lòng nhập Họ & Tên";
        }

        if (helps.isEmpty(this.state.id_number)) {
            formIsValid = false;
            errors["id_number"] = "Vui lòng nhập số CCCD";
        }

        if (helps.isEmpty(this.state.employee_id)) {
            formIsValid = false;
            errors["employee_id"] = "Vui lòng nhập MSNV";
        }

        if (helps.isEmpty(this.state.phone)) {
            formIsValid = false;
            errors["phone"] = "Vui lòng nhập số điện thoại của nhân sự";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    cancelForm(e) {
        e.preventDefault();
        let _confirm = window.confirm('Bạn muốn hủy Form? Lưu ý: tất cả dữ liệu đã nhập sẽ bị xóa. ');
        if (_confirm) {
            this.props.history.push('/employees')
        }
        return false;
    }


    render() {

        var id_issuance_date_val = '';
        if (this.state.id_issuance_date) {
            if (!helps.isEmpty(this.state.id_issuance_date) && typeof this.state.id_issuance_date === 'object') {
                id_issuance_date_val = helps.formatDate_for_input(this.state.id_issuance_date.toDate(), 'YYYY-MM-DD');
            } else {
                id_issuance_date_val = helps.formatDate_for_input(this.state.id_issuance_date, 'YYYY-MM-DD');
            }

        } else {
            id_issuance_date_val = new Date().toISOString().slice(0, 10);
        }

        var filter_date_val = '';
        if (this.state.joint_date) {
            if (!helps.isEmpty(this.state.joint_date) && typeof this.state.joint_date === 'object') {
                filter_date_val = helps.formatDate_for_input(this.state.joint_date.toDate(), 'YYYY-MM-DD');
            } else {
                filter_date_val = helps.formatDate_for_input(this.state.joint_date, 'YYYY-MM-DD');
            }

        } else {
            filter_date_val = new Date().toISOString().slice(0, 10);
        }
        var filter_birthdate_val = '';
        if (this.state.birthdate) {

            if (!helps.isEmpty(this.state.birthdate) && typeof this.state.birthdate === 'object') {
                filter_birthdate_val = helps.formatDate_for_input(this.state.birthdate.toDate(), 'YYYY-MM-DD');
            } else {
                filter_birthdate_val = helps.formatDate_for_input(this.state.birthdate, 'YYYY-MM-DD');
            }
        } else {
            filter_birthdate_val = new Date().toISOString().slice(0, 10);
        }
        //console.log(filter_birthdate_val);
        return (
            <CRow>
                <CCol xl={12}>
                    <CForm onSubmit={(e) => this.handleSubmit(e)}>
                        <CCard>
                            <CCardHeader>
                                {this.props.form_header}
                            </CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol md={6}>
                                        <div className="mb-3">
                                            <CRow>
                                                <CCol>
                                                    <CLabel htmlFor="input-fullname">Họ & Tên:</CLabel>
                                                    <CInput
                                                        type="text"
                                                        name="fullname"
                                                        id="input-fullname"
                                                        value={this.state.fullname}
                                                        required
                                                        className='font-weight-bold'
                                                        onChange={(e) => { this.handleChange_Get_ID('fullname', e) }}
                                                        onBlur={(e) => { this.handleChange_Get_ID('fullname', e) }}
                                                    />
                                                    <small className="text-danger">{this.state.errors['fullname']}</small>
                                                </CCol>
                                                <CCol>
                                                    <CLabel htmlFor="input-joint_date">Ngày vào công ty:</CLabel>
                                                    <input
                                                        type="date"
                                                        value={filter_date_val}
                                                        placeholder="dd/mm/yyyy"
                                                        min="2022-07-28"
                                                        required
                                                        onChange={e => this.handleChangeFilterDate(new Date(e.target.value).getTime())}
                                                        className="form-control"
                                                    />
                                                    <small className="text-danger">{this.state.errors['joint_date']}</small>
                                                </CCol>
                                            </CRow>
                                        </div>
                                        <div className="mb-3">
                                            <CRow>
                                                <CCol md={3} className={'pt-3'}>
                                                    <CLabel htmlFor="input-cccd">Lưu CCCD/CMT:</CLabel>
                                                    <CFormGroup variant="custom-radio" inline>
                                                        <CInputCheckbox
                                                            type="checkbox"
                                                            name="cccd"
                                                            id="input-cccd"
                                                            title='Còn lưu giữ CCCD/CMT?'
                                                            className='mt-2 cursor-pointer'
                                                            checked={this.state.cccd}
                                                            value={1}
                                                            onChange={(e) => { this.handleChangeCCCD(e) }}

                                                        />
                                                    </CFormGroup>
                                                </CCol>
                                                <CCol md={4}>
                                                    <CLabel htmlFor="input-id_number">Số CCCD/CMT:</CLabel>
                                                    <CInputGroup>
                                                        <CInput
                                                            type="text"
                                                            name="id_number"
                                                            id="input-id_number"
                                                            value={this.state.id_number}
                                                            required
                                                            onChange={(e) => { this.handleChange_Get_ID('id_number', e) }}
                                                            onBlur={(e) => { this.handleChange_Get_ID('id_number', e) }}

                                                        />
                                                        <CInputGroupAppend>
                                                            <CInputGroupText className={'bg-secondary'}>
                                                                <Hint content={'Dạng số, bắt buộc nhập!'} />
                                                            </CInputGroupText>
                                                        </CInputGroupAppend>
                                                    </CInputGroup>

                                                    <small className="text-danger">{this.state.errors['id_number']}</small>
                                                </CCol>
                                                <CCol md={2}>
                                                    <CLabel htmlFor="input-id_type">
                                                        Loại:
                                                    </CLabel>
                                                    <CSelect
                                                        id='input-id_type'
                                                        name="id_type"
                                                        value={this.state.id_type}
                                                        onChange={(e) => { this.handleChange('id_type', e) }}
                                                    >
                                                        {configs.id_types && Object.keys(configs.id_types).map((_key) => {
                                                            return (
                                                                <option key={"id_type" + _key} value={_key}>{configs.id_types[_key]}</option>
                                                            )
                                                        }
                                                        )}
                                                    </CSelect>
                                                </CCol>
                                                <CCol md={3}>
                                                    <CLabel htmlFor="input-id_issuance_date">Ngày cấp:</CLabel>
                                                    <CInput
                                                        id='input-id_issuance_date'
                                                        name='id_issuance_date'
                                                        type="date"
                                                        value={id_issuance_date_val}
                                                        placeholder="dd/mm/yyyy"
                                                        onChange={e => this.handleChangeDate(new Date(e.target.value).getTime(), 'id_issuance_date')}
                                                        className="form-control"
                                                    />
                                                    <small className="text-danger">{this.state.errors['id_issuance_date']}</small>
                                                </CCol>
                                            </CRow>
                                        </div>
                                        <div className="mb-3" key={this.state.employee_id}>
                                            <CRow>
                                                <CCol>
                                                    <CLabel htmlFor="input-employee_id">MSNV:</CLabel>
                                                    <CInputGroup>
                                                        <CInput
                                                            type="text"
                                                            name="employee_id"
                                                            id="input-employee_id"
                                                            value={this.state.employee_id}
                                                            required
                                                            readOnly
                                                            onChange={(e) => { this.handleChange_Get_ID('employee_id', e) }}
                                                            className='font-weight-bold'
                                                        />
                                                        <CInputGroupAppend>
                                                            <CInputGroupText className={'bg-secondary'}>
                                                                <Hint content={'MSNV được tạo tự động'} />
                                                            </CInputGroupText>
                                                        </CInputGroupAppend>
                                                    </CInputGroup>
                                                    <small className="text-danger">{this.state.errors['employee_id']}</small>
                                                </CCol>
                                                <CCol>
                                                    <CLabel htmlFor="input-birthdate">Ngày sinh:</CLabel>
                                                    <input
                                                        type="date"
                                                        id='input-birthdate'
                                                        value={filter_birthdate_val}
                                                        placeholder="dd/mm/yyyy"
                                                        onChange={e => this.handleChangeDate(new Date(e.target.value).getTime(), 'birthdate')}
                                                        className="form-control"
                                                    />
                                                    <small className="text-danger">{this.state.errors['birthdate']}</small>
                                                </CCol>
                                            </CRow>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-gen" className="mr-3">Giới tính:</CLabel>
                                            {configs.gens && Object.keys(configs.gens).map((_key) => {
                                                return (
                                                    <CFormGroup variant="custom-radio" key={"gen_" + _key} inline>
                                                        <CInputRadio
                                                            custom
                                                            id={"gen_" + _key}
                                                            name="gen"
                                                            value={_key}
                                                            checked={this.state.gen === _key}
                                                            onChange={(e) => { this.handleChange('gen', e) }}
                                                        />
                                                        <CLabel variant="custom-checkbox" htmlFor={"gen_" + _key} className="cursor-pointer">
                                                            {configs.gens[_key]}
                                                        </CLabel>
                                                    </CFormGroup>
                                                )
                                            }
                                            )}
                                            <CInvalidFeedback className="help-block">{this.state.errors['api_url']}</CInvalidFeedback>
                                        </div>
                                        <div className="mb-3">
                                            <CRow>
                                                <CCol>
                                                    <CFormGroup>
                                                        <div><CLabel htmlFor="input-branches">Chi nhánh:</CLabel></div>
                                                        {configs.branches && Object.keys(configs.branches).map((_key) => {
                                                            return (
                                                                <CFormGroup variant="custom-radio" key={"branch" + _key}>
                                                                    <CInputRadio
                                                                        custom
                                                                        id={"branch" + _key}
                                                                        name="branch"
                                                                        value={_key}
                                                                        checked={this.state.branch === _key}
                                                                        onChange={(e) => { this.handleChange('branch', e) }}
                                                                    />
                                                                    <CLabel variant="custom-checkbox" htmlFor={"branch" + _key} className="cursor-pointer">
                                                                        {configs.branches[_key]}
                                                                    </CLabel>
                                                                </CFormGroup>
                                                            )
                                                        }
                                                        )}
                                                    </CFormGroup>
                                                </CCol>
                                                <CCol>
                                                    <CFormGroup>
                                                        <div><CLabel htmlFor="input-groups">Khối:</CLabel></div>
                                                        {configs.groups && Object.keys(configs.groups).map((_key) => {
                                                            return (
                                                                <CFormGroup variant="custom-radio" key={"group" + _key}>
                                                                    <CInputRadio
                                                                        custom
                                                                        id={"group" + _key}
                                                                        name="group"
                                                                        value={_key}
                                                                        checked={this.state.group === _key}
                                                                        onChange={(e) => { this.handleChange('group', e) }}
                                                                    />
                                                                    <CLabel variant="custom-checkbox" htmlFor={"group" + _key} className="cursor-pointer">
                                                                        {configs.groups[_key]}
                                                                    </CLabel>
                                                                </CFormGroup>
                                                            )
                                                        }
                                                        )}
                                                    </CFormGroup>
                                                </CCol>
                                                <CCol>
                                                    <CFormGroup>
                                                        <div><CLabel htmlFor="input-department">Bộ phận/Phòng ban:</CLabel></div>
                                                        {configs.departments && Object.keys(configs.departments).map((_key) => {
                                                            return (
                                                                <CFormGroup variant="custom-radio" key={"department" + _key}>
                                                                    <CInputRadio
                                                                        custom
                                                                        id={"department" + _key}
                                                                        name="department"
                                                                        value={_key}
                                                                        checked={this.state.department === _key}
                                                                        onChange={(e) => { this.handleChange('department', e) }}
                                                                    />
                                                                    <CLabel variant="custom-checkbox" htmlFor={"department" + _key} className="cursor-pointer">
                                                                        {configs.departments[_key]}
                                                                    </CLabel>
                                                                </CFormGroup>
                                                            )
                                                        }
                                                        )}
                                                    </CFormGroup>
                                                </CCol>
                                                <CCol>
                                                    <CFormGroup>
                                                        <div><CLabel htmlFor="input-position">Vị trí:</CLabel></div>
                                                        {configs.positions && Object.keys(configs.positions).map((_key) => {
                                                            return (
                                                                <CFormGroup variant="custom-radio" key={"position" + _key}>
                                                                    <CInputRadio
                                                                        custom
                                                                        id={"position" + _key}
                                                                        name="position"
                                                                        value={_key}
                                                                        checked={this.state.position === _key}
                                                                        onChange={(e) => { this.handleChange('position', e) }}
                                                                    />
                                                                    <CLabel variant="custom-checkbox" htmlFor={"position" + _key} className="cursor-pointer">
                                                                        {configs.positions[_key]}
                                                                    </CLabel>
                                                                </CFormGroup>
                                                            )
                                                        }
                                                        )}
                                                    </CFormGroup>
                                                </CCol>
                                                <CCol>
                                                    <CFormGroup>
                                                        <div><CLabel htmlFor="input-job_type">Loại hình:</CLabel></div>
                                                        {configs.job_types && Object.keys(configs.job_types).map((_key, index) => {
                                                            // if (Object.keys(configs.job_types).length - 1 === index) return (<></>);
                                                            return (
                                                                <CFormGroup variant="custom-radio" key={"job_type" + _key}>
                                                                    <CInputRadio
                                                                        custom
                                                                        id={"job_type" + _key}
                                                                        name="job_type"
                                                                        value={_key}
                                                                        checked={this.state.job_type === _key}
                                                                        onChange={(e) => { this.handleChange('job_type', e) }}
                                                                    />
                                                                    <CLabel variant="custom-checkbox" htmlFor={"job_type" + _key} className="cursor-pointer">
                                                                        {configs.job_types[_key]}
                                                                    </CLabel>
                                                                </CFormGroup>
                                                            )
                                                        }
                                                        )}
                                                    </CFormGroup>
                                                </CCol>
                                            </CRow>
                                        </div>
                                    </CCol>
                                    <CCol md={6}>
                                        <div className="mb-3">
                                            <CRow>
                                                <CCol md={6}>
                                                    <CLabel htmlFor="input-phone">Số ĐT:</CLabel>
                                                    <CInput
                                                        type="text"
                                                        name="phone"
                                                        id="input-phone"
                                                        value={this.state.phone}
                                                        onChange={(e) => { this.handleChange('phone', e) }}
                                                    />
                                                    <small className="text-danger">{this.state.errors['phone']}</small>
                                                </CCol>
                                                <CCol md={6}>
                                                    <CLabel htmlFor="input-basic_salary">Lương CB:</CLabel>
                                                    <CInput
                                                        type="text"
                                                        name="basic_salary"
                                                        id="input-basic_salary"
                                                        value={this.state.basic_salary}
                                                        onChange={(e) => { this.handleChange('basic_salary', e) }}
                                                    />
                                                    <CInvalidFeedback className="help-block">{this.state.errors['basic_salary']}</CInvalidFeedback>
                                                </CCol>
                                            </CRow>

                                        </div>
                                        <div className="mb-3">
                                            <CRow>
                                                <CCol md={6}>
                                                    <CLabel htmlFor="input-id_number">Chiều cao(cm):</CLabel>
                                                    <CInput
                                                        type="number"
                                                        name="height"
                                                        id="input-height"
                                                        value={this.state.height}
                                                        onChange={(e) => { this.handleChange('height', e) }}
                                                    />
                                                    <CInvalidFeedback className="help-block">{this.state.errors['height']}</CInvalidFeedback>
                                                </CCol>
                                                <CCol md={6}>
                                                    <CLabel htmlFor="input-id_number">Cân nặng(kg):</CLabel>
                                                    <CInput
                                                        type="number"
                                                        name="weight"
                                                        id="input-weight"
                                                        value={this.state.weight}
                                                        onChange={(e) => { this.handleChange('weight', e) }}
                                                    />
                                                    <CInvalidFeedback className="help-block">{this.state.errors['weight']}</CInvalidFeedback>
                                                </CCol>
                                            </CRow>
                                        </div>
                                        <div className="mb-3">
                                            <CRow>
                                                <CCol md={4}>
                                                    <CFormGroup>
                                                        <CLabel htmlFor="input-bank_account_number">Số TK Ngân hàng:</CLabel>
                                                        <CInput
                                                            type="number"
                                                            name="bank_account_number"
                                                            id="input-bank_account_number"
                                                            value={this.state.bank_account_number}
                                                            onChange={(e) => { this.handleChange('bank_account_number', e) }}
                                                        />
                                                    </CFormGroup>
                                                </CCol>
                                                <CCol md={4}>
                                                    <CFormGroup>
                                                        <CLabel htmlFor="input-bank_name">Ngân hàng:</CLabel>
                                                        <CInput
                                                            type="text"
                                                            name="bank_name"
                                                            id="input-bank_name"
                                                            value={this.state.bank_name}
                                                            onChange={(e) => { this.handleChange('bank_name', e) }}
                                                        />
                                                        <CFormText className="help-block">&#47;&#47; Nhập mã BIN Ngân Hàng. VD: Ngân hàng Techcombank, có thể nhập: <b>Techcombank</b> hoặc <b>TCB</b> hoặc <b>970407</b> <a href='https:&#47;&#47;www.sbv.gov.vn/webcenter/portal/vi/menu/trangchu/ttvnq/htmtcqht' target='_blank' rel="noreferrer"><i>(tham khảo)</i></a></CFormText>
                                                    </CFormGroup>
                                                </CCol>
                                                <CCol md={4}>
                                                    <CLabel htmlFor="input-bank_note">Ghi chú TK Ngân Hàng:</CLabel>
                                                    <CInput
                                                        type="text"
                                                        name="bank_note"
                                                        id="input-bank_note"
                                                        value={this.state.bank_note}
                                                        onChange={(e) => { this.handleChange('bank_note', e) }}
                                                    />
                                                    <CFormText className="help-block">&#47;&#47; Những Ghi chú về Tài khoản Ngân hàng.</CFormText>
                                                </CCol>
                                            </CRow>
                                        </div>
                                        <CRow className="mb-3">
                                            <CCol>
                                                <CLabel htmlFor="input-permanent_address">Địa chỉ thường trú:</CLabel>
                                                <CInput
                                                    type="text"
                                                    name="permanent_address"
                                                    id="input-permanent_address"
                                                    defaultValue={this.state.permanent_address}
                                                    onChange={(e) => { this.handleChange('permanent_address', e) }}
                                                />
                                                <CFormText className="help-block">&#47;&#47; Như trong CCCD hoặc SYLL</CFormText>
                                            </CCol>
                                            <CCol>
                                                <CLabel htmlFor="input-home_town">Nguyên quán:</CLabel>
                                                <CInput
                                                    type="text"
                                                    name="home_town"
                                                    id="input-home_town"
                                                    defaultValue={this.state.home_town}
                                                    onChange={(e) => { this.handleChange('home_town', e) }}
                                                />
                                                <CFormText className="help-block">&#47;&#47; Như trong CCCD hoặc SYLL</CFormText>
                                            </CCol>
                                        </CRow>
                                        <CRow className="mb-3">
                                            <CCol>
                                                <CLabel htmlFor="input-reference_name">Người Tham khảo:</CLabel>
                                                <CInput
                                                    type="text"
                                                    name="reference_name"
                                                    id="input-reference_name"
                                                    defaultValue={this.state.reference_name}
                                                    onChange={(e) => { this.handleChange('reference_name', e) }}
                                                />
                                                <CFormText className="help-block">&#47;&#47; Họ & Tên người thân, người tham chiếu</CFormText>
                                            </CCol>
                                            <CCol>
                                                <CLabel htmlFor="input-reference_phone">Số ĐT Người Tham khảo:</CLabel>
                                                <CInput
                                                    type="text"
                                                    name="reference_phone"
                                                    id="input-reference_phone"
                                                    defaultValue={this.state.reference_phone}
                                                    onChange={(e) => { this.handleChange('reference_phone', e) }}
                                                />
                                                <CFormText className="help-block">&#47;&#47; Số điện thoại người thân, người tham chiếu</CFormText>
                                            </CCol>
                                            <CCol>
                                                <CLabel htmlFor="input-reference_relationship">Quan hệ:</CLabel>
                                                <CInput
                                                    type="text"
                                                    name="reference_relationship"
                                                    id="input-reference_relationship"
                                                    defaultValue={this.state.reference_relationship}
                                                    onChange={(e) => { this.handleChange('reference_relationship', e) }}
                                                />
                                                <CFormText className="help-block">&#47;&#47; Quan hệ với nhân sự</CFormText>
                                            </CCol>
                                        </CRow>
                                    </CCol>
                                </CRow>
                                <hr />
                                <CRow>
                                    <CCol>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-recruited_by">Tuyển dụng vào bởi:</CLabel>
                                            <Select
                                                id="input-recruited_by"
                                                placeholder="chọn..."
                                                isClearable={true}
                                                options={this.state.recruited_by_options}
                                                value={this.state.default_recruited_by_option}
                                                onChange={(choice) => { this.handleChangeSelect(choice, 'recruited_by') }}
                                            />
                                            <small className="text-danger">{this.state.errors['recruited_by']}</small>
                                        </div>
                                    </CCol>
                                    <CCol>

                                    </CCol>
                                    <CCol>

                                    </CCol>
                                </CRow>
                            </CCardBody>
                            <CCardFooter>
                                <CTooltip content={'Lưu'}>
                                    <CButton type="submit" color="primary" className={' mr-2'}>
                                        <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                        Lưu
                                    </CButton>
                                </CTooltip>
                                <CTooltip content={'Hủy'}>
                                    <CButton type="button" color="secondary" onClick={(e) => { this.cancelForm(e) }}>
                                        <CIcon name="cil-x" className="mfe-1 mt-0"></CIcon>
                                        Hủy
                                    </CButton>
                                </CTooltip>
                            </CCardFooter>
                        </CCard>
                    </CForm>
                </CCol>
            </CRow>
        )
    }
}
export default EmployeeAddForm;

