import { bookConstants } from "src/_constants";
import { bookService } from "src/_services/accounting";
import { alertActions } from "src/_actions/alert.actions";

export const bookActions = {
    getAll,
    add,
    update,
    _delete,
    getCountBooks,
    updateField,
}

function getAll(params, limit = 1000) {
    return dispatch => {
        dispatch(request());
        return bookService.getAll(params, limit)
            .then(
                books => {
                    dispatch(success(books));
                    return books;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: bookConstants.GET_LIST_REQUEST, params } }
    function success(books, params) { return { type: bookConstants.GET_LIST_SUCCESS, books, params } }
    function failure(error) { return { type: bookConstants.GET_LIST_FAILURE, error } }
}

function add(book) {
    return dispatch => {
        dispatch(request(book));
        bookService.add(book)
            .then(
                book => {
                    dispatch(success(book));
                    dispatch(alertActions.success('Tạo Thu/Chi thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(book) { return { type: bookConstants.ADD_REQUEST, book: book } }
    function success(book) { return { type: bookConstants.ADD_SUCCESS, book: book } }
    function failure(error) { return { type: bookConstants.ADD_FAILURE, error } }
}

function update(book) {
    return dispatch => {
        dispatch(request(book));
        bookService.update(book)
            .then(
                book => {
                    dispatch(success(book));
                    dispatch(alertActions.success('Chỉnh sửa Thu/Chi thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(book) { return { type: bookConstants.EDIT_REQUEST, book: book } }
    function success(book) { return { type: bookConstants.EDIT_SUCCESS, book: book } }
    function failure(error) { return { type: bookConstants.EDIT_FAILURE, error } }
}

function updateField(book, field) {
    return dispatch => {
        dispatch(request(book, field));
        bookService.updateField(book, field)
            .then(
                book => {
                    dispatch(success(book, field));
                    dispatch(alertActions.success('Cập nhật sửa Thu/Chi thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(book, field) { return { type: bookConstants.UPDATE_FIELD_REQUEST, book: book, field: field } }
    function success(book, field) { return { type: bookConstants.UPDATE_FIELD_SUCCESS, book: book, field: field } }
    function failure(error) { return { type: bookConstants.UPDATE_FIELD_FAILURE, error } }
}


function _delete(book) {
    return dispatch => {
        dispatch(request(book));
        bookService._delete(book)
            .then(
                book => {
                    dispatch(success(book));
                    dispatch(alertActions.success('Xoá Thu/Chi thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(book) { return { type: bookConstants.DELETE_REQUEST, book: book } }
    function success(book) { return { type: bookConstants.DELETE_SUCCESS, book: book } }
    function failure(error) { return { type: bookConstants.DELETE_FAILURE, error } }
}

function getCountBooks(book_date, type) {
    return dispatch => {
        return bookService.getCountBooks(book_date, type);
    };
}