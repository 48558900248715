import { db } from "src/dbConfigs";
import { helps } from "src/_helpers";
export const bookService = {
    getAll,
    getById,
    _delete,
    add,
    update,
    updateField,
    getCountBooks,
    correctBooks,
}
async function getAll(params, _limit = 1000) {
    try {
        let ref = db.collection('acc_books');
        var _query = {};
        var curent_date = new Date();

        if (!helps.isEmpty(params)) {
            if (params.curent_date) {
                curent_date = params.curent_date;
            }
            var start_date = new Date(curent_date.getFullYear(), curent_date.getMonth(), 1);
            start_date = new Date(start_date.setHours(0, 0, 0));
            var end_date = new Date(curent_date.getFullYear(), curent_date.getMonth() + 1, 0);
            end_date = new Date(end_date.setHours(23, 59, 59));
            //console.log(curent_date, start_date, end_date);

            _query = ref.where('book_date', '>=', new Date(start_date))
                .where('book_date', '<=', new Date(end_date))
                .limit(_limit);
        } else {
            _query = ref.orderBy("book_date", "desc")
                .limit(_limit);
        }

        const snapshot = await _query.get();

        const books = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));

        await Promise.all(
            books.map(async (book) => {
                if (book.book_date) {
                    book['book_date'] = book['book_date'].toDate();
                }
                if (book.added_time) {
                    book['added_time'] = book['added_time'].toDate();
                }
                if (book.book_period_from) {
                    book['book_period_from'] = book['book_period_from'].toDate();
                }
                if (book.book_period_to) {
                    book['book_period_to'] = book['book_period_to'].toDate();
                }
                if (book.approved_time) {
                    book['approved_time'] = book['approved_time'].toDate();
                }
                if (book.processed_date) {
                    book['processed_date'] = book['processed_date'].toDate();
                }
            })
        );
        //correctBooks(books);
        return books;
    } catch (error) {
        return error;
    }
}

async function getLastMonthBook(current_month) {
    let last_month = new Date(current_month);
    last_month.setMonth(last_month.getMonth() - 1);
    let books = await getAll({ curent_date: last_month }, 10000);
    books.sort(function (a, b) {
        let b_count = b.count ? b.count : 0;
        let a_count = a.count ? a.count : 0;
        return b_count - a_count;
    });
    return books[0];
}

async function correctBooks(books) {
    let _books = books.filter(book => !book.approval_required || (book.approval_required && book.approved));
    _books = _books.sort(function (a, b) {
        let b_count = b.count ? b.count : 0;
        let a_count = a.count ? a.count : 0;
        return a_count - b_count;

        //return new Date(a.added_time).getTime() - new Date(b.added_time).getTime();
    });
    //console.log(_books)
    let books_out = _books.filter(book => book.type === 'out');
    let books_in = _books.filter(book => book.type === 'in');

    await Promise.all(
        books_out.map(async (book, index) => {
            book['index'] = index + 1;
            updateField(book, 'index');
            let year = book.book_date.getFullYear();
            let month = (book.book_date.getMonth() + 1).toString().padStart(2, "0");
            book['book_id'] = 'SAC-' + month + year + '-' + book['index'];
            updateField(book, 'book_id');
        })
    );
    await Promise.all(
        books_in.map(async (book, index) => {
            book['index'] = index + 1;
            updateField(book, 'index');
            let year = book.book_date.getFullYear();
            let month = (book.book_date.getMonth() + 1).toString().padStart(2, "0");
            book['book_id'] = 'SAT-' + month + year + '-' + book['index'];
            updateField(book, 'book_id');
        })
    );

    await Promise.all(
        _books.map(async (book, index) => {
            book['count'] = index + 1;
            updateField(book, 'count');

            let amount = parseInt(book.amount);
            let prev_stock = 0;
            let prev_book = _books[index - 1] ? _books[index - 1] : await getLastMonthBook(book.book_date);
            if (prev_book) {
                prev_stock = parseInt(prev_book.stock)
            }
            //console.log(index, book, prev_book)
            if (book.type === 'out') {
                book['stock'] = prev_stock - amount;
                updateField(book, 'stock');
            } else if (book.type === 'in') {
                book['stock'] = prev_stock + amount;
                updateField(book, 'stock');
            }
        })
    );

}

async function getCountBooks(book_date, type = '') {
    try {
        let ref = db.collection('acc_books');
        var _query = {};
        var start_date = new Date(book_date.getFullYear(), book_date.getMonth(), 1);
        start_date = new Date(start_date.setHours(0, 0, 0));
        var end_date = new Date(book_date.getFullYear(), book_date.getMonth() + 1, 0);
        end_date = new Date(end_date.setHours(23, 59, 59));

        if (type !== '') {
            _query = ref.where('book_date', '>=', new Date(start_date))
                .where('book_date', '<=', new Date(end_date))
                .where('type', '==', type);
        } else {
            _query = ref.where('book_date', '>=', new Date(start_date))
                .where('book_date', '<=', new Date(end_date))
        }
        const snapshot = await _query.get();
        const books = snapshot.docs.map(doc => ({
            ...doc.data(),
        }));
        return books.length;
    } catch (error) {
        return error;
    }
}

async function getById(id) {
    return await db.collection("acc_books").doc(id)
        .get()
        .then((doc) => {
            if (doc.exists) {
                return doc.data();
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        })
        .catch((error) => {
            console.log("Error getting document:", error);
        });
}

async function add(book) {
    try {
        const docRef = await db.collection("acc_books").add(book);
        book.id = docRef.id;
        update(book);
        console.log("Firebase: book has been added successfully!");
        return book;
    } catch (error) {
        console.error("Error adding book: ", error);
    }
}

async function update(book) {
    var Ref = db.collection("acc_books").doc(book.id);

    try {
        await Ref.update(book);
        console.log("Firebase: book has been updated successfully!");
        return book;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}

async function updateField(document, field) {
    console.log(document)
    let dbRef = db.collection("acc_books").doc(document.id);
    try {
        await dbRef.update({
            [field]: document[field]
        }).then(() => {
            console.log("Firebase: document field has been updated successfully!");
        });
        return document;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        return error;
    }
}


async function _delete(book) {
    var Ref = db.collection("acc_books").doc(book.id);

    try {
        await Ref.delete();
        console.log("Firebase: book has been deleted successfully!");
        return book;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}
