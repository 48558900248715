import React from 'react';

import { SettingsContainer } from './containers/settings';
import { UsersContainer, UserEditContainer, UserAddContainer } from './containers/users';
import { DashboardContainer } from './containers/dashboard';
import {
  EmployeesContainer,
  EmployeesAddContainer,
  EmployeeContainer,
  ViolationSettingsContainer,
  ViolationsListContainer,
  CandidatesContainer,
  CandidatesFormContainer,
  CandidateContainer,
  RecruitmentKPIContainer,
} from './containers/employees';
import { CustomersContainer, CustomersFormContainer, CustomerContainer, CustomersImportContainer } from './containers/customers';
import { ProjectContainer, ProjectsContainer, ProjectFormContainer } from './containers/projects';
import { ChecksContainer } from './containers/checks';
import { TasksContainer, TasksFormContainer, TaskContainer } from './containers/tasks';
import { BooksContainer, TaxonomiesContainer, TimekeepingContainer } from './containers/accounting';
import { DocumentsContainer } from './containers/documents';
import { HousingsContainer, HousingContainer, HousingsFormContainer } from './containers/housings';
import { AssetsContainer, ItemsContainer, ItemsFormContainer, ItemContainer, StoresContainer, StoresFormContainer, QTTContainer, QTTINContainer, OutContainer, PUTContainer } from './containers/assets';
import { VendorsContainer, VendorsFormContainer, VendorContainer } from './containers/vendors';


const User = React.lazy(() => import('./views/users/User'));

const routes = [
  { path: '/', exact: true, name: 'Sảnh', component: DashboardContainer },
  { path: '/dashboard', name: 'Sảnh', component: DashboardContainer },

  { path: '/accounting/timekeeping', name: 'Bảng Chấm Công', exact: true, component: TimekeepingContainer },
  { path: '/accounting/books', name: 'Sổ Chu/Chi', exact: true, component: BooksContainer },
  { path: '/accounting/taxonomies', name: 'Quản Lý Khoản Mục', exact: true, component: TaxonomiesContainer },

  { path: '/assets', name: 'Quản Lý Tài Sản', exact: true, component: AssetsContainer },
  { path: '/assets/stores', name: 'Quản Lý Danh Sách Kho', exact: true, component: StoresContainer },
  { path: '/assets/stores/add_edit', name: 'Thêm Kho', exact: true, component: StoresFormContainer },
  { path: '/assets/stores/add_edit/:id', name: 'Chỉnh Sửa Thông Tin Kho', exact: true, component: StoresFormContainer },
  { path: '/assets/items', name: 'Quản Lý Danh Mục Tài Sản', exact: true, component: ItemsContainer },
  { path: '/assets/items/add_edit/:id', name: 'Chỉnh Sửa Danh Mục Tài Sản', exact: true, component: ItemsFormContainer },
  { path: '/assets/items/add_edit', name: 'Thêm Danh Mục Tài Sản', exact: true, component: ItemsFormContainer },
  { path: '/assets/items/view/:id', name: 'Danh Mục Tài Sản', exact: true, component: ItemContainer },
  { path: '/assets/:category_id', name: '', exact: true, component: QTTContainer },
  { path: '/assets/:category_id/in/:asset_id', name: 'Nhập / Thu Hồi', exact: true, component: QTTINContainer },
  { path: '/assets/:category_id/in', name: 'Nhập / Thu Hồi', exact: true, component: QTTINContainer },
  { path: '/assets/:category_id/out/:asset_id', name: 'Xuất / Bàn Giao', exact: true, component: OutContainer },
  { path: '/assets/:category_id/out', name: 'Xuất / Bàn Giao', exact: true, component: OutContainer },
  { path: '/assets/:category_id/put/:asset_id', name: 'Hủy / Lưu Kho', exact: true, component: PUTContainer },
  { path: '/assets/:category_id/put', name: 'Hủy / Lưu Kho', exact: true, component: PUTContainer },

  { path: '/vendors', name: 'Quản Lý Nhà Cung Cấp', exact: true, component: VendorsContainer },
  { path: '/vendors/add_edit/:id', exact: true, name: 'Chỉnh Sửa Nhà Cung Cấp', component: VendorsFormContainer },
  { path: '/vendors/add_edit', name: 'Thêm Nhà Cung Cấp', exact: true, component: VendorsFormContainer },
  { path: '/vendors/view/:id', exact: true, name: 'Xem Nhà Cung Cấp', component: VendorContainer },

  { path: '/tasks', name: 'Công Việc', exact: true, component: TasksContainer },
  { path: '/tasks/add_edit', exact: true, name: 'Thêm Công Việc', component: TasksFormContainer },
  { path: '/tasks/add_edit/:id', exact: true, name: 'Chỉnh Sửa Công Việc', component: TasksFormContainer },
  { path: '/tasks/view/:id', exact: true, name: 'Xem Công Việc', component: TaskContainer },

  { path: '/checks', name: 'Check in - Check out', component: ChecksContainer },

  { path: '/settings', name: 'Settings', component: SettingsContainer },

  { path: '/customers', exact: true, name: 'Danh Sách Khách Hàng', component: CustomersContainer },
  { path: '/customers/leads', exact: true, name: 'Danh Sách Cơ Hội', component: CustomersContainer },
  { path: '/customers/potentials', exact: true, name: 'Danh Sách Khách Hàng Tiềm Năng', component: CustomersContainer },
  { path: '/customers/import', exact: true, name: 'Import', component: CustomersImportContainer },
  { path: '/customers/add_edit/:id', exact: true, name: 'Chỉnh Sửa Khách Hàng', component: CustomersFormContainer },
  { path: '/customers/add_edit', exact: true, name: 'Thêm Khách Hàng', component: CustomersFormContainer },
  { path: '/customers/view/:id', exact: true, name: 'Xem Khách Hàng', component: CustomerContainer },

  { path: '/employees', exact: true, name: 'Danh Sách Nhân Viên', component: EmployeesContainer },
  { path: '/employees/recruitmentkpi', exact: true, name: 'KPI Tuyển Dụng', component: RecruitmentKPIContainer },
  { path: '/employees/violations', exact: true, name: 'Danh Sách Vi Phạm', component: ViolationsListContainer },
  { path: '/employees/violation-settings', exact: true, name: 'Cài đặt Vi Phạm', component: ViolationSettingsContainer },
  { path: '/employees/candidates', exact: true, name: 'Quản Lý Ứng Viên', component: CandidatesContainer },
  { path: '/employees/candidates/add_edit/:id', exact: true, name: 'Chỉnh Sửa Thông Tin Ứng Viên', component: CandidatesFormContainer },
  { path: '/employees/candidates/add_edit', exact: true, name: 'Thêm Ứng Viên', component: CandidatesFormContainer },
  { path: '/employees/candidates/view/:id', exact: true, name: 'Xem Thông Tin Ứng Viên', component: CandidateContainer },
  { path: '/employees/add_edit/:id', exact: true, name: 'Chỉnh Sửa Nhân Viên', component: EmployeesAddContainer },
  { path: '/employees/add_edit', exact: true, name: 'Thêm Nhân Viên', component: EmployeesAddContainer },
  { path: '/employees/view/:id', exact: true, name: 'Xem Nhân Viên', component: EmployeeContainer },

  { path: '/projects', exact: true, name: 'Quản Lý Mục Tiêu', component: ProjectsContainer },
  { path: '/projects/add_edit/:id', exact: true, name: 'Chỉnh Sửa Mục Tiêu', component: ProjectFormContainer },
  { path: '/projects/add_edit', exact: true, name: 'Thêm Mục Tiêu Mới', component: ProjectFormContainer },
  { path: '/projects/view/:id', exact: true, name: 'Xem Mục Tiêu', component: ProjectContainer },

  { path: '/housings', exact: true, name: 'Quản Lý Nhà Đội', component: HousingsContainer },
  { path: '/housings/add_edit/:id', exact: true, name: 'Chỉnh Sửa Nhà Đội', component: HousingsFormContainer },
  { path: '/housings/add_edit', exact: true, name: 'Thêm Nhà Đội Mới', component: HousingsFormContainer },
  { path: '/housings/view/:id', exact: true, name: 'Xem Nhà Đội', component: HousingContainer },

  { path: '/users', exact: true, name: 'Users', component: UsersContainer },
  { path: '/users/add_edit/:id', exact: true, name: 'Chỉnh Sửa Người Dùng', component: UserEditContainer },
  { path: '/users/add_edit', exact: true, name: 'Thêm Mới Người Dùng', component: UserEditContainer },
  { path: '/users/:id', exact: true, name: 'User Details', component: User },

  { path: '/documents', exact: true, name: 'Tài Liệu', component: DocumentsContainer },
];

export default routes;
