import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CCardFooter,
    CButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { ThePermission } from '../ThePermission';
import Customers from 'src/views/customers/Customers';
import { customerActions, noteActions, projectActions, provinceActions, tagActions, userActions } from '../../_actions';
import { helps } from 'src/_helpers';

class CustomersContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page_header: 'Danh Sách Khách Hàng',
            customer_type: 'customer'
        }
    }

    componentDidMount() {
        let path = this.props.match.path;
        var page_header = '';
        var customer_type = '';
        if (path.includes('leads')) {
            page_header = 'Danh Sách Cơ Hội';
            customer_type = 'lead';
        } else if (path.includes('potentials')) {
            page_header = 'Danh Sách Khách Hàng Tiềm Năng';
            customer_type = 'potential';
        } else {
            page_header = 'Danh Sách Khách Hàng';
            customer_type = 'customer';
        }

        this.setState({ page_header: page_header, customer_type: customer_type });
        if (!this.props.users) {
            this.props.getUsers();
        }

        if (!this.props.provinces) {
            this.props.getProvinces();
        }

        if (helps.isEmpty(this.props.projects)) {
            this.props.getProjects();
        }

        this.props.getTags('customers');

        this.props.getNotes('customers');

        this.props.getAll(customer_type);
    }

    render() {
        return (
            <ThePermission {... this.props} component="customers" action="view" alertOut={true}>
                <CRow key={helps.convertToSlug(this.state.page_header)}>
                    <CCol xl={12}>
                        <CCard>
                            <CCardHeader>
                                <CRow>
                                    <CCol>
                                        {this.state.page_header}
                                    </CCol>
                                    <CCol className='text-right'>
                                        <CButton
                                            disabled
                                            type="button"
                                            color="primary"
                                            onClick={() => { this.props.history.push('/customers/import') }}
                                        >
                                            <CIcon name="cil-cloud-upload" className="mfe-1 mt-0"></CIcon>
                                            Import
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </CCardHeader>
                            <CCardBody className='customers-table'>
                                <Customers {... this.props} customers={this.props.customers} />
                            </CCardBody >
                            <CCardFooter>
                                Tổng: <b>{this.props.customers && <>{this.props.customers.length}</>}</b>
                            </CCardFooter>
                        </CCard >
                    </CCol >
                </CRow >
            </ThePermission>

        );
    }
}

function mapStateToProps(state) {
    const { loading, customers } = state.customerReducer;
    const { provinces } = state.provinceReducer;
    const { projects } = state.projectReducer;
    const { user } = state.authentication;
    const { users } = state.userReducer;
    const { tags } = state.tagReducer;
    const { notes } = state.noteReducer;
    return { loading, customers, provinces, user, users, projects, tags, notes };
}

const actionCreators = {
    getAll: customerActions.getAll,
    setParams: customerActions.setParams,
    getProvinces: provinceActions.getAll,
    getUsers: userActions.getAll,
    getProjects: projectActions.getAll,
    getTags: tagActions.getAll,
    getNotes: noteActions.getAll,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(CustomersContainer);
export { connectedContainer as CustomersContainer };