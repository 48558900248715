import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CCallout,
    CCard,
    CCardHeader,
    CCardBody,
    CLink,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from '../../_helpers';
import Loading from 'src/containers/_loading';


class EmployeeManeuvers extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const { maneuvers, projects, employees, users } = this.props;
        if (maneuvers && maneuvers.length > 0) {
            maneuvers.map((maneuver) => {
                maneuver['_maneuver_time'] = helps.formatDateTime_from_Timestamp(maneuver.maneuver_time);
            })
        }
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }

        return (
            <CRow>
                <CCol xl={12}>
                    <CCard>
                        <CCardHeader>
                            Lịch Sử Điều Động
                        </CCardHeader>
                        <CCardBody>
                            <div className='maneuvers-container'>
                                {maneuvers && maneuvers.map((maneuver) => {
                                    let maneuver_employee = helps.getItemFromArr(employees, maneuver.employee_id, 'employee_id');
                                    let maneuver_from_project = helps.getItemFromArr(projects, maneuver.from_project_id);
                                    let maneuver_project = helps.getItemFromArr(projects, maneuver.project_id);
                                    return (
                                        <CCallout key={maneuver.id} color="success" className={'bg-white'}>
                                            <CRow className="mb-2">
                                                <CCol id={maneuver.id}>
                                                    <i className='text-muted'><u>Điều:</u> </i> <b>{maneuver_employee.fullname}</b>({maneuver.employee_id})
                                                    {maneuver.from_project_id &&
                                                        <>
                                                            <i> <u>từ</u> </i>
                                                            <b>{maneuver_from_project.name}</b>
                                                        </>
                                                    }
                                                    <i> <u>đến</u> </i>
                                                    <b>{maneuver_project.name}</b>
                                                    {maneuver.note && <div><small className='text-muted'>( <i className='font-weight-bold'><u>Ghi Chú:</u></i> {maneuver.note} )</small></div>}
                                                    {!helps.isEmpty(maneuver.attachment) && maneuver.attachment.length > 0 && maneuver.attachment.map((file) => {
                                                        return (
                                                            <div>
                                                                <small><CIcon name='cil-file' size='sm'></CIcon> <CLink href={file.url} target='_blank'>{file.name}</CLink></small>
                                                            </div>
                                                        );
                                                    })
                                                    }
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol xl={12} md={12}>
                                                    <div className='text-right'>
                                                        <small className='text-muted'>
                                                            <i>Điều bởi: </i><b>{helps.getUserAlias(users, maneuver.maneuver_by)} </b>
                                                            |
                                                            <i> Ngày: </i><b>{helps.formatDateTime_from_Timestamp(maneuver.maneuver_time)}</b>
                                                        </small>
                                                    </div>
                                                </CCol>
                                            </CRow>
                                            <hr />
                                        </CCallout>
                                    );
                                })}
                            </div>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        )
    }
}


export default EmployeeManeuvers;
