import { bookConstants } from "src/_constants";

export function bookReducer(state = {}, actions) {
    switch (actions.type) {
        // GET LIST    
        case bookConstants.GET_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case bookConstants.GET_LIST_SUCCESS:
            return {
                ...state,
                books: actions.books,
                loading: false,
            }
        case bookConstants.GET_LIST_FAILURE:
            return {
                ...state,
                loading: true,
                error: actions.error,
            }

        // Add
        case bookConstants.ADD_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case bookConstants.ADD_SUCCESS:
            state.books.push(actions.book);
            return {
                ...state,
                loading: false,
            }
        case bookConstants.ADD_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }

        // Edit
        case bookConstants.EDIT_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case bookConstants.EDIT_SUCCESS:
            var _books = state.books;
            var foundIndex = _books.findIndex(book => book.id === actions.book.id);
            _books[foundIndex] = actions.book;
            return {
                ...state,
                books: _books,
                loading: false,
            }
        case bookConstants.EDIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }
        // Update Field
        case bookConstants.UPDATE_FIELD_REQUEST:
            return {
                ...state,
            }
        case bookConstants.UPDATE_FIELD_SUCCESS:
            var _books = state.books;
            var foundIndex = _books.findIndex(book => book.id === actions.book.id);
            _books[foundIndex] = actions.book;
            return {
                ...state,
                books: _books,
            }
        case bookConstants.UPDATE_FIELD_FAILURE:
            return {
                ...state,
                error: actions.error,
            }
        // Delete 
        case bookConstants.DELETE_REQUEST:
            return {
                ...state,
                books: state.books.map(book =>
                    book.id === actions.book.id
                        ? { ...book, deleting: true }
                        : book
                )
            };
        case bookConstants.DELETE_SUCCESS:
            return {
                books: state.books.filter(book => book.id !== actions.book.id)
            };
        case bookConstants.DELETE_FAILURE:
            return {
                ...state,
                books: state.books.map(book => {
                    if (book.id === actions.book.id) {
                        const { deleting, ...bookCopy } = book;
                        return { ...bookCopy, deleteError: actions.error };
                    }
                    return book;
                })
            };

        default: return state;
    }
}