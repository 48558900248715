import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThePermission } from '../ThePermission';
import { customerActions, noteActions, projectActions, provinceActions, tagActions, userActions } from '../../_actions';
import { helps } from 'src/_helpers';
import CustomersImport from './CustomersImport';

class CustomersImportContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page_header: 'Danh Sách Khách Hàng',
            customer_type: 'customer'
        }
    }

    componentDidMount() {
        let path = this.props.match.path;
        var page_header = '';
        var customer_type = '';
        if (path.includes('leads')) {
            page_header = 'Danh Sách Cơ Hội';
            customer_type = 'lead';
        } else if (path.includes('potentials')) {
            page_header = 'Danh Sách Khách Hàng Tiềm Năng';
            customer_type = 'potential';
        } else {
            page_header = 'Danh Sách Khách Hàng';
            customer_type = 'customer';
        }

        this.setState({ page_header: page_header, customer_type: customer_type });
        if (!this.props.users) {
            this.props.getUsers();
        }

        if (!this.props.provinces) {
            this.props.getProvinces();
        }

        if (helps.isEmpty(this.props.projects)) {
            this.props.getProjects();
        }

        if (helps.isEmpty(this.props.tags)) {
            this.props.getTags('customers');
        }

        if (helps.isEmpty(this.props.notes)) {
            this.props.getNotes('customers');
        }

        this.props.getAll('lead');
    }

    render() {
        return (
            <ThePermission {... this.props} component="settings" action="view" alertOut={true}>
                <CustomersImport {... this.props} />
            </ThePermission>

        );
    }
}

function mapStateToProps(state) {
    const { loading, customers } = state.customerReducer;
    const { provinces } = state.provinceReducer;
    const { projects } = state.projectReducer;
    const { user } = state.authentication;
    const { users } = state.userReducer;
    const { tags } = state.tagReducer;
    const { notes } = state.noteReducer;
    return { loading, customers, provinces, user, users, projects, tags, notes };
}

const actionCreators = {
    getAll: customerActions.getAll,
    setParams: customerActions.setParams,
    getProvinces: provinceActions.getAll,
    getUsers: userActions.getAll,
    getProjects: projectActions.getAll,
    getTags: tagActions.getAll,
    getNotes: noteActions.getAll,
    add: customerActions.add,
    _delete: customerActions._delete,
    update: customerActions.update,
    addTag: tagActions.add,
    deleteTag: tagActions._delete,
    addNote: noteActions.add,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(CustomersImportContainer);
export { connectedContainer as CustomersImportContainer };