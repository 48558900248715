import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CTabs, CNav, CNavItem, CNavLink, CTabContent, CTabPane,
    CRow, CCol,
    CButton,
    CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Loading from 'src/containers/_loading';
import { ThePermission } from '../ThePermission';
import {
    projectActions,
    fileActions,
    provinceActions,
    noteActions,
    positionActions,
    userActions,
    employeeActions,
} from '../../_actions';
import { helps } from 'src/_helpers';
import { ContactsContainer } from '../contacts';
import { NotesContainer } from '../notes';
import { FilesContainer } from '../files';
import { PositionsContainer } from '../positions';
import Project from 'src/views/projects/Project';
import ProjectEmployees from 'src/views/projects/ProjectEmployees';
import { ManeuversContainer } from '../maneuver';
import { TasksComponentContainer } from '../tasks/TasksComponentContainer';
import { AssetsComponentContainer } from '../assets/AssetsComponentContainer';
import { ProjectTimekeepingContainer } from './ProjectTimekeepingContainer';
import Areas from 'src/views/projects/Areas';

class ProjectContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            project: {},
            object: 'projects',
            project_id: '',
            activeTab: 'info',
        }
        this.handleChangeActiveTab = this.handleChangeActiveTab.bind(this);
    }

    componentDidMount() {
        this.getProjects().then((projects) => {
            let project = this.getProject(projects);
            this.setState({ project: project });
        });

        let employees = this.props.employees;
        if (helps.isEmpty(employees)) this.props.getEmployees();
        this.getPositions();
    }

    getProject(projects) {
        let project_id = this.props.match.params.id;

        var project = {};
        if (project_id && projects) {
            project = helps.getItemFromArr(projects, project_id);
        }
        return project;
    }

    async getProjects() {
        const { projects } = this.props;
        var _projects = projects;
        if (helps.isEmpty(_projects)) {
            _projects = await this.props.getAll()
        }
        return _projects;
    }

    async getPositions() {
        const { positions } = this.props;
        if (helps.isEmpty(positions)) return await this.props.getPositions();
        else return positions;
    }

    async getUsers() {
        const { users } = this.props;
        if (helps.isEmpty(users)) await this.props.getUsers();
    }

    handleChangeActiveTab(tab) {
        this.setState({ activeTab: tab });
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        if (helps.isEmpty(this.props.projects)) {
            return (
                <Loading></Loading>
            );
        }
        if (helps.isEmpty(this.state.project)) {
            return (
                <Loading></Loading>
            );
        }
        const project = this.state.project;

        return (
            <ThePermission {... this.props} component="projects" action="view">
                <CCard>
                    <CCardHeader>
                        <CRow>
                            <CCol>
                                Mục Tiêu: <b>{project.name}{project.tax_number && <> - {project.tax_number}</>}</b>
                                <CRow className="mb-1">
                                    <CCol>
                                        <small className='font-italic text-muted' style={{ fontSize: '12px' }}><CIcon name="cil-location-pin"></CIcon> {project.address}</small>
                                    </CCol>
                                </CRow>
                            </CCol>
                            <CCol className='text-right'>
                                <ThePermission {... this.props} component="projects" action="edit">
                                    <CTooltip content={'Chỉnh sửa Thông tin Mục Tiêu: ' + project.name}>
                                        <CButton
                                            color="primary"
                                            size="sm"
                                            title="Chỉnh Sửa"
                                            className='mt-3'
                                            onClick={() => { this.props.history.push(`/projects/add_edit/${project.id}`) }}
                                        >
                                            <CIcon name="cil-pen-nib" className="mfe-1 mt-0"></CIcon>
                                            Chỉnh Sửa
                                        </CButton>
                                    </CTooltip>
                                </ThePermission>
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CCardBody>
                        <CTabs activeTab={this.state.activeTab} onActiveTabChange={this.handleChangeActiveTab}>
                            <CNav variant="tabs">
                                <CNavItem>
                                    <CNavLink data-tab="info">
                                        <h6>Thông Tin</h6>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="timekeeping">
                                        <h6>Bảng Chấm Công</h6>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="tasks">
                                        <h6>Công Việc</h6>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="positions">
                                        <h6>Vị Trí</h6>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="contacts">
                                        <h6>Liên Hệ</h6>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="assets">
                                        <h6>Bàn Giao / Thu Hồi</h6>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="notes">
                                        <h6>Ghi Chú</h6>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="files">
                                        <h6>Tài Liệu</h6>
                                    </CNavLink>
                                </CNavItem>
                            </CNav>
                            <CTabContent className="py-3">
                                <CTabPane data-tab="info">
                                    <CRow>
                                        <CCol xl={7} md={7} sm={12}>
                                            <ProjectEmployees {... this.props} project={project} />
                                        </CCol>
                                        <CCol xl={5} md={5} sm={12}>
                                            <ManeuversContainer {... this.props} project={project} />
                                        </CCol>
                                    </CRow>
                                    <Project {... this.props} projects={this.props.projects} project={project} />
                                </CTabPane>
                                <CTabPane data-tab="timekeeping">
                                    {this.state.activeTab === 'timekeeping' && <ProjectTimekeepingContainer {... this.props} project={project} />}
                                </CTabPane>
                                <CTabPane data-tab="tasks">
                                    {this.state.activeTab === 'tasks' && <TasksComponentContainer {... this.props} object='projects' object_id={project.id} />}
                                </CTabPane>
                                <CTabPane data-tab="positions">
                                    {this.state.activeTab === 'positions' && <Areas {... this.props} project={project} />}
                                    {this.state.activeTab === 'positions' && <PositionsContainer {... this.props} project={project} />}
                                </CTabPane>
                                <CTabPane data-tab="contacts">
                                    {this.state.activeTab === 'contacts' && <ContactsContainer {... this.props} customer_id={project.customer_id} project_id={project.id} />}
                                </CTabPane>
                                <CTabPane data-tab="assets">
                                    {this.state.activeTab === 'assets' && <AssetsComponentContainer {... this.props} object='project' object_data={project} />}
                                </CTabPane>
                                <CTabPane data-tab="notes">
                                    {this.state.activeTab === 'notes' && <NotesContainer {... this.props} object={this.state.object} object_id={project.id} />}
                                </CTabPane>
                                <CTabPane data-tab="files">
                                    {this.state.activeTab === 'files' && <FilesContainer {... this.props} object={this.state.object} object_id={project.id} />}
                                </CTabPane>
                            </CTabContent>
                        </CTabs>
                    </CCardBody>
                </CCard>

            </ThePermission>

        );
    }
}

function mapStateToProps(state) {
    const { loading, projects } = state.projectReducer;
    const { users } = state.userReducer;
    const { provinces } = state.provinceReducer;
    const { contacts } = state.contactReducer;
    const { notes } = state.noteReducer;
    const { files } = state.fileReducer;
    const { positions } = state.positionReducer;
    const { employees } = state.employeeReducer;
    const { user } = state.authentication;
    return { loading, projects, users, contacts, notes, files, provinces, positions, employees, user };
}

const actionCreators = {
    getAll: projectActions.getAll,
    updateProject: projectActions.update,
    updateField: projectActions.updateField,
    getUser: userActions.getUser,
    addUser: userActions.addUser,
    getUsers: userActions.getAll,
    getNotes: noteActions.getAll,
    getProvinces: provinceActions.getAll,
    uploadFile: fileActions.uploadFile,
    deleteFile: fileActions.deleteFile,
    getFiles: fileActions.getAll,
    getPositions: positionActions.getAll,
    getEmployees: employeeActions.getAll,
    updateEmployee: employeeActions.updateEmployee,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(ProjectContainer);
export { connectedContainer as ProjectContainer };